@tailwind base;
@tailwind components;
@tailwind utilities;

a{
text-decoration: none;
}


@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
  url( "./fonts/Helvetica/Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "Mogena";
  src: local("Mogena"),
    url("./fonts/Mogena/Mogena.ttf") format("truetype");
}

.font-face-hl {
  font-family: "Helvetica";
}

.font-face-mg {
  font-family: "Mogena";
}

@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.animate-fade {
  animation: fade 2.3s infinite;
}

/* @media (min-width: 1024px) {
  .desktop-height {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
    flex: 1;
  }
} */